var site = site || {};
site.BingMaps = function () {
	//console.log('refresh22.bing-maps.js loaded');

	//NOTE: MAP WON'T LOAD WITHOUT WIDTH/HEIGHT SET ON DIV

	var multiPinMap = function () {
		//console.log('refresh22.bing-maps.multiPinMap()');

		//var self = this;
		var map = null;
		var pinLocationsArray = null;
		var loadMap = function (locationsArray, domElement, zoomLevel) {
			// console.log('multiPinMap.loadMap()');
			// console.log('multiPinMap.loadMap()', locationsArray, domElement.id, zoomLevel);


			pinLocationsArray = locationsArray;
			var latitude = 44.832017349893526,
				longitude = -89.84687250000002;
			var center = new Microsoft.Maps.Location(latitude, longitude);
			map = new Microsoft.Maps.Map('#' + domElement.id, {
				mapTypeId: Microsoft.Maps.MapTypeId.canvasLight, //aerial,//canvasDark//grayscale//canvasLight
				enableSearchLogo: false,
				//showMapTypeSelector: false,
				showDashboard: false,
				zoom: 5,
				center: center
			});
			// map.getZoomRange = function () {
			// 	return {
			// 		max: 15,
			// 		min: 5
			// 	};
			// };
			// Microsoft.Maps.Events.addHandler(map, 'viewchangestart', function () {
			// 	if (map.getZoom() <= map.getZoomRange().min) {
			// 		map.setView({
			// 			'zoom': map.getZoomRange().min,
			// 			'animate': false
			// 		});
			// 	} else if (map.getZoom() >= map.getZoomRange().max) {
			// 		map.setView({
			// 			'zoom': map.getZoomRange().max,
			// 			'animate': false
			// 		});
			// 	}
			// });

			var x = 0;
			pinLocationsArray.forEach(location => {
				console.log('pinLocationsArray.location:', location);
				location.PinText = x + 1;
				AddLocation(location);
				x++;
			});

			if (zoomLevel != 'undefined' && x == 1) {
				console.log('map.setView:', locationsArray);
				var center = new Microsoft.Maps.Location(locationsArray[0].Latitude, locationsArray[0].Longitude);
				map.setView({
					center: center,
					zoom: zoomLevel
				});

			} else {
				console.log('use bestMapView()');
				bestMapView($(domElement).children(".MicrosoftMap").width(), $(domElement).children(".MicrosoftMap").height(), 75);
			}

			console.log('map:', map);


		}


		var isRoadTrip = false;
		var AddLocation = function (item) {
			console.log('multiPinMap.AddLocation()', item);
			var offset = new Microsoft.Maps.Point(0, 5);
			var pinOptions = {
				visible: true,
				textOffset: offset,
				draggable: false,
				iconStyle: 8
			};

			if (site.BingMaps.multiPinMap.IsRoadTrip) {
				pinOptions = pinImageForRoadTrip(pinOptions);
			} else {
				pinOptions = pinImageWithOutText(pinOptions);
			}

			var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(item.Latitude, item.Longitude), pinOptions);
			pin.Address = item.Address;

			map.entities.push(pin);
		};

		return {
			loadMap: loadMap,
			IsRoadTrip: isRoadTrip
		};
	}();

	var pinImageWithOutText = function (pinOptions) {
		console.log('pinImageWithOutText()', pinOptions);
		if (pinOptions == null || pinOptions == undefined)
			pinOptions = {};

		pinOptions.icon = '/images/public/map_images/pushpin_2.png';

		pinOptions.width = 16;
		pinOptions.height = 22;
		return pinOptions;
	};


	var jkTest = function () {
		console.log('refresh22.bing-maps.jkTest()');
	}
	

	return {
		multiPinMap: multiPinMap,
		jkTest: jkTest,
	}
}();